import React                 from 'react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Subnavigation from '../components/Subnavigation';

const StreusalzPage = (props) => {
  return (
    <>
      <HeaderMedia id="privat-1"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Altholzentsorgung',
          'path': '/altholzentsorgung'
        },
        {
          'displayName': 'Kaminholz',
          'path': '/kaminholz'
        },
        {
          'displayName': 'Streusalz',
          'path': '/streusalz'
        },
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle='Sauber, effizient und schnell'>STREUSALZ</Heading>
        <Spacer/>
        <h3>Der Winter kommt bestimmt</h3>
        <p>Bei winterlichen Temperaturen und Niederschlag erhöht sich die Gefahr von überfrierender Nässe, Schneeglätte und Glatteis erheblich und führen auf Strassen, Gehwegen und Parkplätzen schnell zu Unfällen. Eine Vermeidung von Rutschunfällen ist oberstes Gebot. Der Einsatz von Streusalz macht Wege schnell und andauernd eisfrei.</p>
        <Spacer />
        <h3>Bilstein als erstklassiger Lieferant für Streusalz</h3>
        <p>Streusalz in hervorragender Qualität und ohne Klumpenbildung. Streusalz als Gebinde in 25kg und 50kg Säcken zur Befreiung von Schnee- und Eisglätte auf allen Wegen und Strassen.</p>
        <Spacer />
        <h3>Die Räum- und Streupflicht obliegt dem Grundstückseigentümer</h3>
        <p>Die Räum- und Streupflicht obliegt dem Grundstückseigentümer, bei öffentlichen Straßen dem Träger der Straßenbaulast. Die Räum- und Streupflicht für öffentliche Gehwege wird üblicherweise - etwa durch kommunale Satzung - auf die privaten Anlieger der Straße übertragen. Diese übertragen die Pflicht wiederum häufig auf die Mieter der Grundstücke. Wird die Räumpflicht schuldhaft nicht beachtet, haftet der Pflichtige unter Umständen für die Folgen von daraus resultierenden Unfällen. Man spricht dann von einem Verstoß gegen die ihm obliegende Verkehrssicherungspflicht. In diesen Fällen kommt eine zivilrechtliche Haftung aus § 823 Abs.1 BGB in Betracht.</p>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default StreusalzPage;